/**
* @func 防抖动 - 禁止函数重复执行
*
* @param {}
* @param {Int} t: 延迟时间
*/
export const antiShake = (fn, t) => {
    let delay = t || 500
    let timer

    return function () {
        let args = arguments
        if (timer) clearTimeout(timer)
        let callNow = !timer
        timer = setTimeout(() => {
            timer = null
        }, delay)
        if (callNow) fn.apply(this, args)
    }
}
