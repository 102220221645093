/**
 * @func 读取Cookie
 * @param {String} name: 字段名
 * @param {String} defaultValue: cookie获取失败, 默认返回值
 * @returns
 */
function getCookie(name, defaultValue) {
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg)) {
        return unescape(arr[2]);
    } else {
        return defaultValue;

    }
}

export {
    getCookie
}

