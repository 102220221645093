<template>
    <div id="home-page">
        <section class="ant-layout">
            <header class="ant-layout-header">
                <Head></Head>
            </header>

            <main class="ant-layout-content main-section row">
                <a-row  class="page-title">
                    <h1>{{ $t('shortLink.title') }}</h1>
                </a-row>
                <a-row type="flex" justify="center" align="middle">
                    <div class="ant-col ant-col-16">
                        <span class="ant-input-search ant-input-search-enter-button ant-input-search-large ant-input-group-wrapper ant-input-group-wrapper-lg" data-v-a1ccd506="">
                            <span class="ant-input-wrapper ant-input-group">
                                <input :placeholder="$t('shortLink.searchFieldPlaceholder')"
                                    @keyup.enter="generateShortlink(longLink)"
                                    v-model="longLink"
                                    type="text"
                                    class="ant-input ant-input-lg">
                                <span class="ant-input-group-addon">
                                    <button type="button" class="ant-btn ant-btn-primary ant-btn-lg ant-input-search-button" @click="generateShortlink(longLink)">
                                        <span>{{ $t('shortLink.buttonTitle') }}</span>
                                    </button>
                                </span>
                            </span>
                        </span>
                    </div>
                </a-row>
                <br>
                <a-row type="flex" justify="center" align="middle">
                    <div class="result ant-col ant-col-16" v-if="shortLinkObj">
                        <div class="result-top">
                            <a :href="shortLinkObj.qrcode_url" download="qrcode">
                                <img :src="shortLinkObj.qrcode_url" class="qrcode">
                            </a>
                            <div class="linkinfo">
                                <div class="top-link">
                                    <span >{{ $t('shortLink.shortLinkTitle') }}</span><br>
                                    <a :href="shortURLHttps" target="_Blank">
                                        <span style="margin-right:12px">{{ shortLinkObj.short_url }}</span><br>
                                    </a>
                                    <a-icon type="copy" @click="copyBtnClick(shortLinkObj.short_url)"/>
                                </div>
                                <div class="original-link">
                                    <span style="color:#A9A9A9;font-size:12px">{{ $t('shortLink.originalLinkTitle') }}</span>
                                    <a :href="longURLHttps" target="_Blank" style="color:#A9A9A9;font-size:12px">
                                        <span style="margin-right:12px">{{ shortLinkObj.original_url }}</span><br>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </a-row>

                <div class="content">
                    <a-row type="flex" justify="center" align="middle">
                        <a-col :span="10">
                            <img src="https://www.admqr.com/packs/media/images/f-1-3221231ad9c4a389ecdc445b9bb26f3f.png">
                        </a-col>
                        <a-col :span="10">
                            <h1> 便于线上线下分发 </h1>
                            <p> 压缩长连接, 不再让链接占据大量有用篇幅。 </p>
                            <p> 使用短链/二维码分发，便于分析线上/线下的用户数据。 </p>
                        </a-col>
                    </a-row>
                    <a-row type="flex" justify="center" align="middle">
                        <a-col :span="10">
                            <h1> 支持多类型分发 </h1>
                            <p> 根据地域/时间/设备的特性来跳转不同的链接，让你的用户看到最感兴趣的链接，从而流量收益最大化。 </p>
                            <p> 不需要开发成本就可以实现A/B Test，挑选出最受用户欢迎的方案。 </p>
                        </a-col>
                        <a-col :span="10">
                            <img src="https://www.admqr.com/packs/media/images/f-2-6759c4fe671ad14f5ff58c4140607364.png">
                        </a-col>
                    </a-row>
                </div>

            </main>


            <footer class="ant-layout-footer">
                <Foot></Foot>
            </footer>
        </section>
    </div>
</template>


<script>

import Head from '@/components/common/Head'
import Foot from '@/components/common/Foot'
import { fetchShortLinkService, fetchShortLinkPrivateService } from '@/services/shortLinkService.js'
import { antiShake } from '@/utils/antiShake'


export default {
    name: 'HomePage',
    head: {
        title: i18n.t('shortLink.metaTitle'),
        meta:[
            {
                name: 'description',
                content: i18n.t('shortLink.metaDesc')
            },
            {
                name: 'keywords',
                content: i18n.t('shortLink.metaKeywords')
            },
        ]
    },
    components: {
        Head,
        Foot
    },
    data() {
        return {
            visible: false,
            longLink: null,         // 客户输入框 URL
            shortLinkObj: null,     // 接口返回 短链接Object对象
        };
    },
    computed: {
        /**
         * @func 获取短链接 HTTPS String
         */
        shortURLHttps: function() {
            return "https://" + this.shortLinkObj.short_url
        },
        /**
         * @func 获取原始链接 HTTPS String
         */
        longURLHttps: function() {
            return "https://" + this.longLink
        }
    },
    watch: {
        /**
         * 观察 visible 变化, false modal消失, 清空 shortLinkObj 对象
         * 清空 modal 提示信息
         */
        visible: function(val) {
            if (val === false) {
                this.shortLinkObj = null
                this.notiMsg = ""
            }
        }
    },
    methods:{
        // 按钮响应函数 添加心跳检测
        generateShortlink:antiShake(function(longLink) {
            var currentUrl = window.location.pathname;
            console.log(currentUrl)
            this.visible = true

            if(currentUrl === "/home" || currentUrl === "/home/"){
                fetchShortLinkService(longLink, response => {
                    this.shortLinkObj = response.data
                    var short_url = this.shortLinkObj.short_url
                    this.notiMsg = short_url        // 设置 modal 提示信息
                    this.copyToClipboard(short_url) // copy
                    this.$message.success( this.$t('hint.successCopyClipboard') );
                }, error => {
                    // 异常处理
                })
            }
            else if(currentUrl === "/home/private" || currentUrl === "/home/private/"){
                fetchShortLinkPrivateService(longLink, response => {
                    this.shortLinkObj = response.data
                    var short_url = this.shortLinkObj.short_url
                    this.notiMsg = short_url       // 设置 modal 提示信息
                    this.copyToClipboard(short_url) // copy
                }, error => {
                    // 异常处理
                })
            }
        }),
        handleOk(e) {
            console.log(e);
            this.visible = false;
        },
        /**
        * @func Copy button click
        * @param {String} msg: 拷贝内容
        */
        copyBtnClick(msg) {
            this.copyToClipboard(msg)
            this.$message.success( this.$t('hint.successCopyClipboard') );
        },
        /**
         * @func 拷贝文本到剪切板
         * @param {String} msg: 拷贝内容
         */
        copyToClipboard(msg) {
            const input = document.createElement("input");  // 直接构建input
            input.value = msg                               // 设置内容
            document.body.appendChild(input);               // 添加临时实例
            input.select();                                 // 选择实例内容
            document.execCommand("Copy");                   // 执行复制
            document.body.removeChild(input);               // 删除临时实例
        }
    },
    mounted() {
    }

}

</script>


<style scoped>
.content-section {
    padding-top: 100px;
    padding-bottom: 100px;
}
</style>
