import { post } from './request.js'


/**
 * @func 获取IP信息
 *
 * @param {String} domain: domain / IP
 * @return {Object}
 */
export function fetchIPService(domain, callback, error) {

    var url = "/query_ip/"

    var data = {
        "domain": domain
    }
    post(url, data, true)
    .then(function (response) {
        if ("function" == typeof callback) {
            callback(response);
        }
    })
    .catch(function (errorResponse) {
        if ("function" == typeof error) {
            error(errorResponse)
        }
    })

}
