<template>
    <div class="container-footer">
        <!-- link链接 -->
        <div class="footer-links">
            <a> <p>{{ this.$t('footMenu.help') }}</p> </a>
            <a> <p>{{ this.$t('footMenu.privacy') }}</p> </a>
            <a> <p>{{ this.$t('footMenu.clause') }}</p> </a>
            <a> <p>{{ this.$t('footMenu.aboutUS') }}</p> </a>
        </div>
        <!-- logo -->
        <div>
            <p>
                <br>
                <a href="/">
                <img width="150" alt="logo" src="@/assets/images/short-link-logo-with-name.png">
                </a>
            </p>
            <p>
                Copyright © 2021
                <a href="https://114.li/">熊猫短链&lt;114.li&gt;</a>
            </p>
        </div>
    </div>
</template>



<script>
export default {
    name: 'Foot'
}
</script>


<style scoped>
.container-footer {
    margin-top: 10px;
}

/* foot links */
.footer-links p {
    font-size: 16px;
    display:inline-table;
    padding-left: 20px;
    padding-right: 20px;
}
</style>
