import Vue from 'vue'
import Antd from 'ant-design-vue';
import VueCookies from 'vue-cookies'
import md5 from 'js-md5'
//添加谷歌地图
import * as VueGoogleMaps from "vue2-google-maps";

import 'ant-design-vue/dist/antd.css';

import App from './App.vue'
import router from './router'
//添加多语言i18n和根据多语言进行样式设置langSetup
import { i18n, langSetup } from './vendors/lang'

// 加载自定义字体
import '@/assets/font/font.css'

Vue.config.productionTip = false
// 加载 ant design
Vue.use(Antd)
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAFV2sJuFxtCLHUKJx5Zkx93qiaP1giQtQ"
    }
});
// 加载 vue cookies
Vue.use(VueCookies)
// 加载 md5
Vue.prototype.$md5 = md5

/* eslint-disable no-new */
Vue.langSetup = Vue.prototype.$langSetup = langSetup


let instance = new Vue({
    router: router,
    i18n, //多语言i18n
    render: h => h(App),
}).$mount('#app');

export default instance
