module.exports = {

    // Common
    // - hint 提示
    hint: {
        successCopyClipboard: "短链接已复制到剪切板!",
        errorURL: "URL输入有误,请确认后重试!",
        errorMd5: "服务器响应异常,请稍后再试!",
        errorDomainNotFound: "输入的域名暂未注册!",
        errorTooFast: "请求速度太快了, 请稍后再试!"
    },
    // Head菜单栏
    headMenu: {
        shortLink: "短链接",
        queryIP: "IP地址查询",
        langBtnTitleMain: "Language",
        langBtnTitleCN: "Chinese",
        langBtnTitleEN: "English",
    },
    // Foot菜单栏
    footMenu: {
        help: "帮助",
        privacy: "隐私",
        clause: "条款",
        aboutUS: "关于我们",
    },
    // page: 短链接
    shortLink: {
        // page meta
        metaTitle: "熊猫短链--短链接/二维码/短网站/IP查询/图床",
        metaDesc: "熊猫短链接是提供网址缩短链接、二维码生成器，数据流量监控，让网站访问量更真实，实现访问链接或扫描二维码时根据地域、设备、时间段做个性化跳转以及多链接随机跳转服务的营销推广的工具，链接A／B测试、灰度发布等多应用场景，旨在帮助营销推广人员了解并获取用户、优化推广方案、提升流量变现、降低推广成本的短链接二维码流量监控平台",
        metaKeywords: "链接，二维码, 短链接, 短网址, 数据分析, 流量监控, 灰度发布, 营销推广, 短信营销, 短链接和长链接的区别, 二维码转换短链接, 链接变二维码，长链接转短链接，短连接, 短链接生成器,短连接生成,永久短链接生成,转换短链接工具,短网址压缩生成",
        // content
        title: "二维码 / 短链接生成工具",
        searchFieldPlaceholder: "请输入你的链接",
        buttonTitle: "生成",
        originalLinkTitle: "原始链接: ",
        shortLinkTitle: "短链接: ",

    },
    // page: IP地址查询
    queryIP: {
        // page meta
        metaTitle: "熊猫短链--短链接/二维码/短网站/IP查询/图床",
        metaDesc: "熊猫短链接是提供网址缩短链接、二维码生成器，数据流量监控，让网站访问量更真实，实现访问链接或扫描二维码时根据地域、设备、时间段做个性化跳转以及多链接随机跳转服务的营销推广的工具，链接A／B测试、灰度发布等多应用场景，旨在帮助营销推广人员了解并获取用户、优化推广方案、提升流量变现、降低推广成本的短链接二维码流量监控平台",
        metaKeywords: "链接，二维码, 短链接, 短网址, 数据分析, 流量监控, 灰度发布, 营销推广, 短信营销, 短链接和长链接的区别, 二维码转换短链接, 链接变二维码，长链接转短链接，短连接, 短链接生成器,短连接生成,永久短链接生成,转换短链接工具,短网址压缩生成",
        // content
        title: "IP位置查询",
        searchFieldPlaceholder: "请输入你要查询的ip或者网址",
        buttonTitle: "查询",
        tableTitle: "IP地址信息",
        tableSubTitleAddr: "IP地址",
        tableSubTitleStatus: "状态",
        tableSubTitleCountry: "所在国家",
        tableSubTitleCity: "所在城市",
        tableSubTitleTimeZone: "所在时区",
        tableSubTitleLongitude: "所在经度",
        tableSubTitleLatitude: "所在维度"
    },
    // page: 404
    page404: {
        metaTitle: "熊猫短链--短链接/二维码/短网站/IP查询/图床",
        metaDesc: "熊猫短链接是提供网址缩短链接、二维码生成器，数据流量监控，让网站访问量更真实，实现访问链接或扫描二维码时根据地域、设备、时间段做个性化跳转以及多链接随机跳转服务的营销推广的工具，链接A／B测试、灰度发布等多应用场景，旨在帮助营销推广人员了解并获取用户、优化推广方案、提升流量变现、降低推广成本的短链接二维码流量监控平台",
        metaKeywords: "链接，二维码, 短链接, 短网址, 数据分析, 流量监控, 灰度发布, 营销推广, 短信营销, 短链接和长链接的区别, 二维码转换短链接, 链接变二维码，长链接转短链接，短连接, 短链接生成器,短连接生成,永久短链接生成,转换短链接工具,短网址压缩生成",
        hint: "哎呀迷路了...",
        title: "可能的原因：",
        returnBtnTitle: "返回首页",
        line1: "原来的页面不存在了",
        line2: "我们的服务器被外星人劫持了",
    }

}
