/**
 * @func 检测当前运行环境是否非为生产环境
 *
 * @returns {Boolean}: 是否为 生产环境 ( True 生产环境 / False 测试环境 )
 */
function isProdEnv() {
    if (process.env.NODE_ENV === "development") return false
    if (window.location.href.includes('192.168')) return false
    return true
}

export {
    isProdEnv
}
