module.exports = {

    // Common
    // - hint 提示
    hint: {
        successCopyClipboard: "The short link has been copied to the clipboard!",
        errorURL: "The URL is entered incorrectly, please confirm and try again!",
        errorMd5: "The server responded abnormally, please try again later!",
        errorDomainNotFound: "The domain entered has not been registered yet!",
        errorTooFast: "The request is too fast, please try again later!"
    },
    // Head菜单栏
    headMenu: {
        shortLink: "Short Link",
        queryIP: "Get IP Address",
        langBtnTitleMain: "切换语言",
        langBtnTitleCN: "中文",
        langBtnTitleEN: "英文",
    },
    // Foot菜单栏
    footMenu: {
        help: "Help",
        privacy: "Privacy",
        clause: "Clause",
        aboutUS: "About US",
    },
    // 短链接页面
    shortLink: {
        // page meta
        metaTitle: "Panda short Link--short link/QR code/short website/IP query/picture bed",
        metaDesc: "Panda Short Link provide URL shortening links, QR code generators, and data flow monitoring to make website visits more realistic, and realize personalized jumps and multiple links based on region, equipment, and time period when accessing links or scanning QR codes Random jump service marketing and promotion tools, link A/B testing, gray release and other application scenarios, short links designed to help marketing promoters understand and acquire users, optimize promotion plans, increase traffic monetization, and reduce promotion costs. Dimension code traffic monitoring platform.",
        metaKeywords: "Link, QR code, short link, short URL, data analysis, traffic monitoring, gray release, marketing promotion, SMS marketing, the difference between short link and long link, QR code conversion short link, link to QR code, long Link to short link, short link, short link generator, short link generation, permanent short link generation, short link conversion tool, short URL compression generation",
        // content
        title: "QRCode / Short Link generation tool",
        searchFieldPlaceholder: "Please enter your link",
        buttonTitle: "Enter",
        originalLinkTitle: "Original URL: ",
        shortLinkTitle: "The short link is: ",

    },
    // page: IP地址查询
    queryIP: {
        // page meta
        metaTitle: "Panda short Link--short link/QR code/short website/IP query/picture bed",
        metaDesc: "Panda Short Link provide URL shortening links, QR code generators, and data flow monitoring to make website visits more realistic, and realize personalized jumps and multiple links based on region, equipment, and time period when accessing links or scanning QR codes Random jump service marketing and promotion tools, link A/B testing, gray release and other application scenarios, short links designed to help marketing promoters understand and acquire users, optimize promotion plans, increase traffic monetization, and reduce promotion costs. Dimension code traffic monitoring platform.",
        metaKeywords: "Link, QR code, short link, short URL, data analysis, traffic monitoring, gray release, marketing promotion, SMS marketing, the difference between short link and long link, QR code conversion short link, link to QR code, long Link to short link, short link, short link generator, short link generation, permanent short link generation, short link conversion tool, short URL compression generation",
        // content
        title: "Get IP Address",
        searchFieldPlaceholder: "Please enter the IP or URL you want to query",
        buttonTitle: "Search",
        tableTitle: "IP Address Information",
        tableSubTitleAddr: "IP Address",
        tableSubTitleStatus: "IP Status",
        tableSubTitleCountry: "Country",
        tableSubTitleCity: "City",
        tableSubTitleTimeZone: "Time Zone",
        tableSubTitleLongitude: "Longitude",
        tableSubTitleLatitude: "Latitude"
    },
    // page: 404
    page404: {
        // page meta
        metaTitle: "Panda short Link--short link/QR code/short website/IP query/picture bed",
        metaDesc: "Panda Short Link provide URL shortening links, QR code generators, and data flow monitoring to make website visits more realistic, and realize personalized jumps and multiple links based on region, equipment, and time period when accessing links or scanning QR codes Random jump service marketing and promotion tools, link A/B testing, gray release and other application scenarios, short links designed to help marketing promoters understand and acquire users, optimize promotion plans, increase traffic monetization, and reduce promotion costs. Dimension code traffic monitoring platform.",
        metaKeywords: "Link, QR code, short link, short URL, data analysis, traffic monitoring, gray release, marketing promotion, SMS marketing, the difference between short link and long link, QR code conversion short link, link to QR code, long Link to short link, short link, short link generator, short link generation, permanent short link generation, short link conversion tool, short URL compression generation",
        // content
        hint: "Oops lost...",
        title: "Possible reason：",
        returnBtnTitle: "Back Home",
        line1: "The original page no longer exists",
        line2: "Our server was hijacked by aliens",
    }

}
