<template>
    <div id="home-page">
        <section class="ant-layout">
            <header class="ant-layout-header">
                <Head></Head>
            </header>


            <main class="ant-layout-content main-section">
                <a-row  class="page-title">
                    <h1>{{ this.$t('queryIP.title') }}</h1>
                </a-row>
                <a-row type="flex" justify="center" align="middle">
                    <div class="ant-col ant-col-16">
                        <span class="ant-input-search ant-input-search-enter-button ant-input-search-large ant-input-group-wrapper ant-input-group-wrapper-lg" data-v-a1ccd506="">
                            <span class="ant-input-wrapper ant-input-group">
                                <input :placeholder="$t('queryIP.searchFieldPlaceholder')" @keyup.enter="searchIp(ipAddress)" v-model="ipAddress" type="text" class="ant-input ant-input-lg">
                                <span class="ant-input-group-addon">
                                    <button type="button" class="ant-btn ant-btn-primary ant-btn-lg ant-input-search-button" @click="searchIp(ipAddress)">
                                        <span>{{ this.$t('queryIP.buttonTitle') }}</span>
                                    </button>
                                </span>
                            </span>
                        </span>
                    </div>
                </a-row>

                <br>

                <div v-if="ipInfo">
                    <a-row type="flex" justify="center" align="middle">
                        <a-col :span="20">
                            <a-descriptions :title="$t('queryIP.tableTitle')" bordered>
                                <a-descriptions-item :label="$t('queryIP.tableSubTitleAddr')">
                                    {{ ipInfo.ip }}
                                </a-descriptions-item>
                                <a-descriptions-item :label="$t('queryIP.tableSubTitleStatus')" :span="2">
                                <a-badge status="processing" text="Running" />
                                </a-descriptions-item>
                                <a-descriptions-item :label="$t('queryIP.tableSubTitleCountry')">
                                    {{ ipInfo.ip_country }}
                                </a-descriptions-item>
                                <a-descriptions-item :label="$t('queryIP.tableSubTitleCity')" :span="2">
                                    {{ ipInfo.ip_city }}
                                </a-descriptions-item>
                                <a-descriptions-item :label="$t('queryIP.tableSubTitleTimeZone')" :span="3">
                                    {{ ipInfo.ip_time_zone }}
                                </a-descriptions-item>
                                <a-descriptions-item :label="$t('queryIP.tableSubTitleLongitude')">
                                    {{ ipInfo.ip_longitude }}
                                </a-descriptions-item>
                                <a-descriptions-item :label="$t('queryIP.tableSubTitleLatitude')" :span="2">
                                    {{ ipInfo.ip_latitude }}
                                </a-descriptions-item>
                            </a-descriptions>
                        </a-col>
                    </a-row>

                    <a-row v-if="center.lat" type="flex" justify="center" align="middle">
                        <a-col :span="20" class="a-col-map">
                            <gmap-map
                                :center="center"
                                :zoom="12"
                                map-type-id="terrain"
                                style="width: 100%; height: 340px"
                            >
                                <gmap-marker
                                    :key="index"
                                    v-for="(m, index) in markers"
                                    :position="m.position"
                                    :clickable="true"
                                    :draggable="true"
                                    @click="center=m.position"
                                ></gmap-marker>
                            </gmap-map>
                        </a-col>
                    </a-row>

                </div>
                <div v-else>
                    <a-row type="flex" justify="center" align="middle">
                        <a-col :span="20">
                            <a-skeleton />
                        </a-col>
                    </a-row>
                </div>
            </main>

            <footer class="ant-layout-footer">
                <Foot></Foot>
            </footer>
        </section>
    </div>
</template>


<script>

import Head from '@/components/common/Head'
import Foot from '@/components/common/Foot'
import { fetchIPService } from '@/services/ipService.js'
import { antiShake } from '@/utils/antiShake'

export default {

    name: 'IpSearchPage',
    head: {
        title: i18n.t('queryIP.metaTitle'),
        meta:[
            {
                name: 'description',
                content: i18n.t('queryIP.metaDesc')
            },
            {
                name: 'keywords',
                content: i18n.t('queryIP.metaKeywords')
            },
        ]
    },
    components: {
        Head,
        Foot
    },
    data() {
        return {
            visible: false,
            ipAddress: null,
            ipInfo: null,
            centers: {},
            markers: [],
            place: null,
        };
    },
    watch: {
        /**
         * 观察 visible 变化, false modal消失, 清空 shortLink 对象
         * 清空 modal 提示信息
         */
        visible: function(val) {
            if (val === false) {
                this.ipInfo = null
                this.noti_msg = ""
            }
        }
    },
    methods:{
        searchIp:antiShake(function(ipAddress) {
            this.visible = true
                fetchIPService(ipAddress, response => {
                    this.markers = []
                    this.center = {}

                    this.ipInfo = response.data
                    if(typeof(response.data.ip_latitude)=="number" && typeof(response.data.ip_longitude) =="number"){
                        const marker = {
                            lat: response.data.ip_latitude,
                            lng: response.data.ip_longitude,
                        };
                        this.markers.push({ position: marker });
                        this.center = marker;
                    }
                }, error => {
                    // 异常处理
                })
        }),
        handleOk(e) {
            console.log(e);
            this.visible = false;
        },
    },
    mounted() {
    }

}

</script>

<style>

.a-col-map {

    padding-top: 20px;
    padding-bottom: 60px;

}

</style>
