import { get, post } from './request.js'


/**
 * @func 获取URL短链接接口
 *
 * @param {String} original 等待转换URL
 * @return {[Object]}
 */
export function fetchShortLinkService(originalUrl, callback, error) {

    var url = "/urlmapping/get-short/"

    var data = {
        "url": originalUrl
    }
    post(url, data, true)
    .then(function (response) {
        if ("function" == typeof callback) {
            callback(response);
        }
    })
    .catch(function (errorResponse) {
        if ("function" == typeof error) {
            error(errorResponse)
        }
    })

}


/**
 * @func 获取URL短链接接口 = 自用
 *
 * @param {String} original 等待转换URL
 * @return {[Object]}
 */
export function fetchShortLinkPrivateService(originalUrl, callback, error) {

    var url = "/urlmapping/get-short/private/"

    var data = {
        "url": originalUrl
    }
    post(url, data, true)
    .then(function (response) {
        if ("function" == typeof callback) {
            callback(response);
        }
    })
    .catch(function (errorResponse) {
        if ("function" == typeof error) {
            error(errorResponse)
        }
    })

}
