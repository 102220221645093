<template>
    <div class="header header-background sticky">
        <div class="ant-col ant-col-6">
            <a href="/home">
                <img src="@/assets/images/short-link-logo-with-name.png" style="max-width:150px;max-height:150px;">
            </a>
        </div>
        <div class="ant-col ant-col-10 nav-bar">
            <a-menu v-model="current" mode="horizontal">
            <a-menu-item key="home">
                <a href="/home" rel="noopener noreferrer">{{ $t('headMenu.shortLink') }}</a>
            </a-menu-item>
            <a-menu-item key="ipsearch" >
                <a href="/home/ip_search" rel="noopener noreferrer">{{ $t('headMenu.queryIP') }}</a>
            </a-menu-item>
            </a-menu>
        </div>

        <!-- 语言 -->
        <div>
            <a-dropdown>
                <a-menu slot="overlay" @click="handleLangMenuClick">
                    <a-menu-item key="zh_CN">
                        {{ this.$t('headMenu.langBtnTitleCN') }}
                    </a-menu-item>
                    <a-menu-item key="en_US">
                        {{ this.$t('headMenu.langBtnTitleEN') }}
                    </a-menu-item>
                </a-menu>
                <a-button> {{ this.$t('headMenu.langBtnTitleMain') }} <a-icon type="down" /> </a-button>
            </a-dropdown>
        </div>
    </div>

</template>

<script>

export default {
    name: 'Head',
    data() {
        return {
            current: null,
            longLink: null,
        };
    },
    methods: {
        /**
         * @func LangMenuClick
         */
        handleLangMenuClick: function(e) {
            // cookie 保存语言选项
            this.$cookies.set('cookie.lang', e.key, '9999h')
            switch(e.key) {
                case 'zh_CN':
                    this.$i18n.locale = 'zh_CN'
                    break;
                case 'en_US':
                    this.$i18n.locale = 'en_US'
                    break;
                default:
                    break;
            }
        }
    }
}
</script>

<style scoped>
.nav-bar {
    padding-top: 10px;
}

.nav-bar a {
    font-weight: bold;
    font-size: 16px;
}

</style>
