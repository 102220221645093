import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta';


// 开启router
Vue.use(VueRouter)
Vue.use(VueMeta, {
    // 定义组件中的数据对象
    keyName: 'head',
});


// mode 说明了我们使用的是history模式，如果不设置mode，则会使用默认的hash模式。
const router = new VueRouter({
    // 路由模式
    mode: 'history',
    // 配置路由对象
    routes: [
        {
            name: 'Home',
            path: '/home',
            component: () => import('@/pages/HomePage'),
        },
        {
            name: 'Home',
            path: '/home/private',
            component: () => import('@/pages/HomePage'),
        },
        {
            name: 'IPSearch',
            path: '/home/ip_search',
            component: () => import('@/pages/IpSearchPage'),
        },
        {
            name: '404',
            path: '/404',
            component: () => import('@/pages/NotFoundPage')
        },
        {
            path: '*', // 页面不存在的情况下会跳到404页面
            redirect: '/404',
            name: 'notFound',
            hidden: true
        }
    ]
})

export default router
